<template>
  <v-select
    v-model="timezone"
    v-bind="selectProps"
    :items="timezones"
    hide-details="auto"
    menu-props="auto"
  />
</template>

<script>
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  name: 'TimezoneSelect',

  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    prefix: {
      type: String,
      default: ''
    },
    dense: {
      type: Boolean,
      default: false
    },
    solo: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: ''
    },
    dark: {
      type: Boolean,
      default: false
    },
    prependInnerIcon: {
      type: String,
      default: ''
    }
  },

  computed: {
    timezone: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    },
    selectProps() {
      const props = { ...this.$props }
      delete props.value

      return props
    },
    timezones() {
      const localBrowser = dayjs.tz.guess()

      const tzOptions = [
        {
          text: `(UTC+00:00)${this.$t('timezone_utc')}`,
          value: 'utc'
        },
        {
          text: `(${this.getOffsetByLocal(localBrowser)})${this.$t('timezone_browser')}`,
          value: 'browser'
        }
      ]

      const curLocal = this.timezone
      if (!tzOptions.find(o => o.value === curLocal)) {
        // 地區名稱可以得到正確的offset才加到選項中
        const curTzOffset = this.getOffsetByLocal(curLocal)
        if (curTzOffset) {
          tzOptions.push({
            text: `(${curTzOffset})${curLocal}`,
            value: curLocal
          })
        }
      }

      return tzOptions
    }
  },

  methods: {
    getOffsetByLocal(local) {
      let tz
      try {
        tz = dayjs().tz(local, true)
      } catch {
        return
      }

      const tzSign = tz.$offset >= 0 ? '+' : '-'

      const tzOffset = Math.abs(tz.$offset)

      const tzHour = `${parseInt(tzOffset / 60)}`.padStart(2, '0') // 08 | 12
      const tzMin = `${parseInt(tzOffset % 60)}`.padStart(2, '0') // 00 | 30

      const tzString = `UTC${tzSign}${tzHour}:${tzMin}`

      return tzString
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
