<template>
  <v-menu offset-y>
    <template #activator="{ on, attrs }">
      <slot
        name="activator"
        :on="on"
        :attrs="attrs"
      >
        <v-btn
          v-bind="attrs"
          text
          dark
          style="height: 100%;"
          v-on="on"
        >
          <v-icon left>
            mdi-account-circle
          </v-icon>
          {{ userName }}
        </v-btn>
      </slot>
    </template>
    <v-list light>
      <UserUnexpireTokenDialog>
        <template #activator="{ on, attrs }">
          <v-list-item
            v-bind="attrs"
            v-on="on"
          >
            <v-icon class="mr-3">
              mdi-monitor-cellphone-star
            </v-icon>
            {{ $t('token_device') }}
          </v-list-item>
        </template>
      </UserUnexpireTokenDialog>
      <v-list-item @click="logout">
        <v-icon class="mr-3">
          mdi-logout
        </v-icon>
        {{ $t('logout') }}
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import UserUnexpireTokenDialog from '@/components/UserUnexpireTokenDialog'

import { mapGetters } from 'vuex'

export default {
  name: 'UserAvatar',

  components: {
    UserUnexpireTokenDialog
  },

  computed: {
    ...mapGetters({
      userName: 'user/userName'
    })
  },

  methods: {
    logout() {
      this.$router.push({
        name: 'Logout'
      })
    }
  }
}
</script>

<style></style>
