<template>
  <v-app-bar
    class="dashboard-app-bar bg-ease"
    app
  >
    <v-container
      class="py-0"
      style="height: 100%;"
    >
      <v-row
        align="center"
        no-gutters
        style="height: 100%;"
      >
        <!-- go back -->
        <v-col
          cols="auto"
          style="height: 100%;"
        >
          <v-btn
            class="font-weight-black"
            :to="toBack"
            text
            exact
            color="white"
            style=" height: 100%;font-size: 1.375rem;"
          >
            <v-icon>mdi-undo-variant</v-icon>
            <span
              v-if="dashboardTitle"
              class="ml-4"
            >{{ dashboardTitle }}</span>
          </v-btn>
        </v-col>

        <!-- dashboard settings -->
        <v-spacer />

        <v-col cols="auto">
          <TimeRangeSelect dark />
        </v-col>

        <v-col
          class="ml-4"
          cols="auto"
          style="max-width: 280px;"
        >
          <TimezoneSelect
            v-model="timezone"
            dark
            prepend-inner-icon="mdi-earth"
          />
        </v-col>
        <v-col
          class="ml-4"
          cols="auto"
        >
          <TranslationMenu />
        </v-col>

        <v-col
          class="ml-4"
          cols="auto"
          style="height: 100%;"
        >
          <UserAvatar v-if="userName" />
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
import UserAvatar from '@/components/UserAvatar'
import TimezoneSelect from '@/components/TimezoneSelect'
import TranslationMenu from '@/components/TranslationMenu'
import TimeRangeSelect from '@/components/TimeRangeSelect/TimeRangeSelect'

import { mapGetters } from 'vuex'

export default {
  name: 'DashboardAppBar',

  components: {
    UserAvatar,
    TimezoneSelect,
    TranslationMenu,
    TimeRangeSelect
  },

  computed: {
    ...mapGetters({
      userName: 'user/userName',
      getDashboardByRoute: 'dashboards/getDashboardByRoute'
    }),

    dashboard() {
      return this.getDashboardByRoute(this.$route) || {}
    },
    dashboardTitle() {
      return this.dashboard?.title
    },
    toBack() {
      if (this?.$route?.meta?.toPanels) {
        return {
          name: 'Panels',
          params: this.$route.params,
          query: this.$route.query
        }
      }

      return {
        name: 'Dashboards',
        params: this.$route.params,
        query: this.$route.query
      }
    },

    timezone: {
      get() {
        return this?.$route?.query?.timezone || 'utc'
      },
      set(newVal) {
        if (newVal === this?.$route?.query?.timezone) return

        const { name = '', params = {}, query = {} } = this.$route || {}

        this.$router.replace({
          name,
          params,
          query: {
            ...query,
            timezone: newVal
          }
        })
      }
    }
  },

  created() {
    this.fetchUser() // for device token
  },

  methods: {
    fetchUser() {
      return this.$store.dispatch('user/fetchUser')
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-app-bar {
  ::v-deep .v-toolbar__content {
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>
